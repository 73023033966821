<template>
  <section id="clinics" class="clinics">
    <transition name="fade" mode="out-in">
      <div>
        <div class="section-top-row" v-if="['superadmin', 'supermanager'].includes(role)">
          <div class="row row-narrow align-items-center no-print position-relative">
            <div class="col order-md-0 order-0">
              <h1>{{ $t('clinics') }}</h1>
            </div>
            <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
              <SearchTable/>
            </div>
            <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
              <DataTableFilterButton/>
            </div>
            <div class="col-auto order-md-4 order-1 table-view-navigator">
              <DataTableExcel :superadmin="true"/>
            </div>
            <div class="col-auto order-md-5 order-2 table-view-navigator">
              <AddObject module="superadmin"/>
            </div>
          </div>
        </div>
        <div v-if="['manager', 'tech_specialist', 'account_manager'].includes(role)"
             class="pt-4 mb-3">
          <b-row>
            <b-col cols="auto">
              <input type="text"
                     style="max-width: 200px; height: 36px;"
                     class="form-control"
                     v-model="q"
                     :placeholder="$t('search')"/>
            </b-col>
            <b-col cols="auto">
              <button class="btn-themed btn-themed-squared"
                      @click="search">
                {{ $t('search') }}
              </button>
            </b-col>

            <b-col cols="auto" v-if="['account_manager'].includes(role)">
              <AddObject module="superadmin"
                         btnclass="btn-themed-squared"/>
            </b-col>

          </b-row>
        </div>
      </div>
    </transition>
    <div>
      <DataTable :options="options"
                 v-if="['superadmin', 'supermanager'].includes(role) || searchMode"
                 ref="datatable"
                 @checkboxSelected="selectedChange"
                 module="superadmin">
        <template #bottom-bar>
          <div v-if="role === 'superadmin'">
            <b-dropdown-item @click="toggleDeletionQueue(1)">
              Поставить в очередь на удаление
            </b-dropdown-item>
            <b-dropdown-item @click="toggleDeletionQueue(0)">
              Убрать из очереди на удаление
            </b-dropdown-item>
          </div>
        </template>
      </DataTable>

    </div>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import {mapState} from "vuex"
import options from "@/data/superadmin/clinic"
import DataTable from "@/components/parts/general/DataTable"
import {SuperadminService} from "@/services/api.service"

export default {
  name: "SuperAdminClinics",
  components: {
    DataTable,
    DataTableFilterButton,
    AddObject,
    SearchTable,
    DataTableExcel
  },
  head() {
    return {
      title: this.$t('clinics'),
    }
  },
  data() {
    return {
      options: options,
      q: '',
      searchMode: '',
      selected: []
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      newEloquentObject: state => state.datatable.newEloquentObject,
    }),
    role() {
      return this.$store.state.auth.user.role
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setViewModel', 'clinic')
  },
  methods: {
    search() {
      if(this.q) {
        this.searchMode = true
        this.$nextTick(() => {
          this.$store.commit('setSearch',this.q)
        })
      }
    },
    selectedChange(selected) {
      this.selected = selected
    },
    async toggleDeletionQueue(action) {
      if(action) {
        if(!confirm('Поставить эти клиники на очередь на удаление? Через 30 дней после постановки на очередь клиника полностью удалится вместе с записями, пациентами, материалами и тд безвозвратно')) return
      }
      const res = await SuperadminService.toggleDeletionQueue('bulk', action, { ids: this.selected })
      if(res.data) {
        this.$noty.info(this.$t('success_message'))
        this.resetSelection()
      } else {
        this.$noty.error(this.$t('error_message'))
      }
      this.$store.commit('incrementDataTableCounter')
    },
    resetSelection() {
      this.$refs.datatable.cancelSelection()
    },
  },
  watch: {
    q() {
      this.searchMode = false
      setTimeout(() => {
        this.$store.commit('setViewModel', 'clinic')
        this.$store.commit('setEloquentOptions', options)
      }, 10)
    },
    newEloquentObject(val) {
      if(val?.id) {
        this.$noty.info(`Создана клиника id ${val.id}`, {
          timeout: 50000
        })
      }
    },
  }
}
</script>
