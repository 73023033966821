export default {
    adjustment: "Коригування",
    add_all_materials_from_warehouse: "Додати всі матеріали зі складу",
    add_material: "Додати матеріал",
    add_new: "Додати новий",
    adding_new_supplier: "Додавання нового постачальника",
    address: "Адреса",
    addition: "Прихід",
    amount: "Кіл-ть",
    amount_of_stock_on_all_warehouses: "Загальна кількість товару, доступна на всіх складах клініки",
    approve: "Провести",
    approved: "Проведено",
    are_you_sure_you_want_to_delete_group: "Ви впевнені, що хочете видалити групу?",
    are_you_sure_you_want_to_delete_kit: "Ви впевнені, що хочете видалити комплект?",
    are_you_sure_you_want_to_delete_supplier: "Ви впевнені, що хочете видалити постачальника?",
    are_you_sure_you_want_to_delete_warehouse: "Ви впевнені, що хочете видалити склад?",
    artikul: "Артикул",
    bank_requisites: "Банківські реквізити",
    barcode: "Штрихкод",
    beginning_balance: "Початковий залишок",
    bin: "БІН",
    branch: "Філія",
    bulk_addition: "Додати групою",
    by_categories: "За категоріями",
    consumption: "Витрата",
    consumption_manual: "Ручна витрата",
    contact_person: "Контактна особа",
    contragent: "Контрагент",
    cost: "Вартість",
    created: "Створено",
    current_stock: "Поточний залишок",
    destination: "Призначення",
    details: "Деталі",
    difference: "Розбіжність",
    documents: "Документи",
    draft: "Чернетка",
    enter_title_or_barcode: "Уведіть наименування або штрихкод",
    factual_amount: "Кількість за фактом",
    finished: "Проведено",
    from: "від",
    from_warehouse: "Переміщення зі складу",
    from_where: "Звідки",
    general_price: "Загальна сума",
    general_stock: "Загальний залишок",
    group: "Група",
    groups: "Групи",
    id: "Id",
    inventarization: "Інвентаризація",
    Kit: "Комплект",
    kit: "Комплект",
    kits: "Комплекти",
    material: "Матеріал",
    materialgroup: "Група",
    materialstock: "Матеріал",
    materials: "Матеріали",
    movement: "Переміщення",
    movements: "Рухи",
    movement_from: "Переміщення",
    movement_to: "Переміщення",
    nomenclature: "Номенклатура",
    notify_when_stock: "Повідомляти при залишку",
    operation: "Операція",
    parent_category: "Батьківська категорія",
    parent_group: "Батьківська група",
    physical: "Фізичний",
    print: "Роздрукувати",
    prohibit_minus_stocks: "Заборонити списувати в мінус матеріали",
    return: "Повернення",
    returns: "Повернення",
    stocks: "Залишки",
    stocks_archive: "Архів матеріалів",
    supplier: "Постачальник",
    suppliers: "Постачальники",
    to_warehouse: "Переміщення на склад",
    to_where: "Куди",
    type: "Тип",
    unit_of_measure: "Од./вим.",
    virtual: "Віртуальний",
    warehouse_amount: "Кількість на складі",
    warehouses: "Склади",
    warehouseoperation: "Операції",
    write_off: "Списання"
}
